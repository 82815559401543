require('./bootstrap.js');
import 'jquery/dist/jquery.min';
import 'select2/dist/js/select2.min'

import $ from 'jquery';
window.$ = window.jQuery = $;

import 'jquery-ui/ui/widgets/datepicker.js';

import './dashboard'

$(document).ready(function(){

    $(".clickable-row").click(function() {
        window.location = $(this).data("href");
    });

    //Horizontal menu fixed scroll
    $(window).scroll(function() {
        if(window.matchMedia('(min-width: 321px)').matches) {
            var header = $('.horizontal-menu');
            if ($(window).scrollTop() >= 70) {
                $(header).addClass('fixed-on-scroll');
            } else {
                $(header).removeClass('fixed-on-scroll');
            }
        }
    });

    //Horizontal menu in mobile
    $('[data-toggle="horizontal-menu-toggle"]').on("click", function() {
        $(".horizontal-menu .bottom-navbar").toggleClass("header-toggled");
    });

    //Horizontal menu navigation in mobile menu on click
    var navItemClicked = $('.horizontal-menu .page-navigation >.nav-item');
    navItemClicked.on("click", function(event) {
        if(window.matchMedia('(max-width: 320px)').matches) {
            if(!($(this).hasClass('show-submenu'))) {
                navItemClicked.removeClass('show-submenu');
            }
            $(this).toggleClass('show-submenu');
        }
    })

    //Init select2
    $('.select2').select2({
        placeholder: "Select",
        allowClear: true
    });

    //Init jquery-ui datepicker
    $('.datepicker').datepicker({ dateFormat: 'yy-mm-dd' });

    //Simple HTML table search
    var $rows = $('#search-me tr');
    $('.search-table').keyup(function() {
        let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();

        $rows.show().filter(function() {
            let text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
            return !~text.indexOf(val);
        }).hide();
    });

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    $('.edit-treatment').on('click', function(e){
        e.preventDefault();
        let treatmentGroupID = $(this).data('treatment-group');
        let $modal = $('#treatment-modal');

        $modal.modal('show');
        $modal.find('.modal-body').html( show_spinner() );

        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: '/treatment-groups/'+parseInt(treatmentGroupID),
            success: function (data) {
                $modal.find('.modal-body').html( data );
                $('.datepicker').datepicker({
                    dateFormat: 'yy-mm-dd'
                });
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                console.log('Ajax Error: '+errorThrown);
            }
        });
    });

    $(document.body).on('keyup', "#search-treatment", function(e){
        let $search = $(this);
        let val = $search.val();
        $search.parent().find('.results').remove();

        if( val.length > 2 ) {
            $.ajax({
                type: 'GET',
                dataType: 'html',
                url: '/search-treatment/'+val,
                success: function (data) {
                    $search.parent().find('.results').remove();
                    $search.parent().append(data);
                },
                error: function(XMLHttpRequest, textStatus, errorThrown) {
                    console.log('Ajax Error: '+errorThrown);
                }
            });
        }
    });

    $(document.body).on('click', ".results tbody tr", function(e) {
        let searchInput = $('#search-treatment');
        searchInput.val('');
        searchInput.parent().find('.results').remove();

        let name  = $(this).find('.input-name').text();
        let qty   = 1;
        let price = parseFloat($(this).find('.input-price').text());

        $('.selected-treatments').append(
            '<div class="treatment-item mb-3 row">'+
                '<div class="col-md-7"><input type="text" name="items[][name]" class="form-control name" value="'+name+'"></div>'+
                '<div class="col-md-2"><input step="any" min="0" type="number" name="items[][quantity]" class="form-control qty" data-default="'+qty+'" value="'+qty+'"></div>'+
                '<div class="col-md-2"><input step="any" min="0" type="number" name="items[][price]" class="form-control price" data-default-price="'+price+'" value="'+price+'"></div>'+
                '<div class="col-md-1 d-flex justify-content-center align-items-center"><a href="#" class="d-block remove link-danger text-decoration-none text-center h4 m-0"><i class="icon-trash"></i></a></div>' +
            '</div>'
        );

        $('.selected-treatments').find('.treatment-item').each(function(index){
            $(this).find('input').each(function(){
                $(this).attr('name', $(this).attr('name').replace('items[]', 'items['+index+']'));
            });
        });
    });

    $(document.body).on('click', ".treatment-item .remove", function(e) {
        e.preventDefault(e);

        $(this).parent().parent().fadeOut(300, function(){
            $(this).remove();

            $('.selected-treatments').find('.treatment-item').each(function(index){
                $(this).find('input').each(function(){
                    let currentID = $(this).attr('name').match(/\d+/);
                    $(this).attr('name', $(this).attr('name').replace('items['+currentID+']', 'items['+index+']'));
                });
            });
        });
    });

    $(document.body).on('change', '.treatment-item .qty', function(e) {
        let qty = $(this).val();
        let defaultPrice = $(this).parent().parent().find('.price').data('default-price');

        let price = parseFloat(defaultPrice) * parseFloat(qty);
        $(this).parent().parent().find('.price').val( price.toFixed(2) );
    });

    $('.add-treatment').on('click', function(e){
        e.preventDefault();
        let $modal = $('#treatment-modal');
        let animal_id = $(this).data('animal-id');

        $modal.modal('show');
        $modal.find('.modal-body').html( show_spinner() );

        $.ajax({
            type: 'GET',
            dataType: 'html',
            url: '/show-new-treatment/'+parseInt(animal_id),
            success: function (data) {
                $modal.find('.modal-body').html( data );
                $('.datepicker').datepicker({
                    dateFormat: 'yy-mm-dd'
                });
            },
            error: function(XMLHttpRequest, textStatus, errorThrown) {
                console.log('Ajax Error: '+errorThrown);
            }
        });
    });

    $(document.body).on('submit', '#save-treatment-data', function(e) {
        e.preventDefault();
        let $form = $(this);
        let formData = $form.serialize();
        $form.append(show_spinner());
        $form.find('input').removeClass('is-invalid');

        $.ajax({
            type:'POST',
            url:'/create-animal-treatment',
            data: formData,
            success:function(data) {
                $form.find('.overlay').remove();
                if (typeof data.errors == 'object') {
                    $.each(data.errors, function (name, label) {
                        let inputName = name.split('.');
                        $form.find('input[name="'+inputName[0]+'['+inputName[1]+']['+inputName[2]+']"]').addClass('is-invalid');
                    });
                }

                if( data.message.length > 0 ) {
                    if( data.status === 200 ) {
                        createDismissableAlert(data.message, 'success');

                        $form[0].reset();

                        setTimeout(function() {
                            window.location.reload();
                        }, 3500);
                    } else {
                        createDismissableAlert(data.message, 'danger');
                    }
                }
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                console.log('Ajax Error: '+errorThrown);
            }
        });
    });

    $(document.body).on('submit', "#edit-treatment-data", function(e){
        e.preventDefault();
        let $form = $(this);
        let formData = $form.serialize();
        let treatment_group_id = $form.find('input[name=treatment_group_id]').val();

        $.ajax({
            type:'POST',
            url:'/edit-animal-treatment',
            data: {
                data : formData,
                group_id : parseInt(treatment_group_id)
            },
            success:function(data) {
                if( data.status === 200 ) {
                    createDismissableAlert(data.message, 'success');
                }
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                console.log('Ajax Error: '+errorThrown);
            }
        });
    });

    $(document.body).on('click', '.delete-group-item', function(e) {
        e.preventDefault();
        let $item = $(this);
        let item_id = $item.data('item-id');

        $.ajax({
            type:'POST',
            url:'/delete-group-item/'+parseInt(item_id),
            success:function(data) {
                if( data.status === true ) {
                    $item.parent().parent().fadeOut(300);
                    $('#treatment-'+data.treatment_group_id).find('.group-price').text(data.price);
                }
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                console.log('Ajax Error: '+errorThrown);
            }
        });
    });
})

/**
 * Show Spinner
 * @returns {string}
 */
function show_spinner() {
    return '<div class="overlay"><div class="spinner-border text-secondary d-block m-auto" role="status"><span class="visually-hidden"></span></div></div>';
}

/**
 * Call a dismissible alert with a required type
 * @param message
 * @param type primary, success, warning, danger
 * @returns {string}
 */
function createDismissableAlert(message, type) {
    let alert =
        '<div class="alert alert-'+type+' alert-dismissible fade show" role="alert">' +
        message +
        '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">' +
        '<span hidden="true" >&times;</span >' +
        '</button>' +
        '</div>';

    $('.notice-wrapper').show().prepend(alert).delay(3000).fadeOut(300, function(){
        $(this).find('.alert').remove();
    });
}
